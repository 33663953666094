import React from "react"
import Seo from "../components/seo"
import Menu from "../components/menu"
import Footer from "../components/footer"
import "../css/style.css"
import Horario from "../components/horario"

const IndexPage = () => (
  <>
    <Seo title="Menú" />
    <Menu />
    <div className="footer-1">
      <Horario />
      <Footer />
    </div>
  </>
)

export default IndexPage
