import React from "react"
import "../css/style.css"

export default function Horario() {
  return (
    <section className="horario-area">
      <p className="horario-text">Horario de servicio: 1pm a 2am.</p>
      <p className="horario-text">Apertura de terraza: 5pm.</p>
      <p className="horario-text">No hacemos reservaciones.</p>
    </section>
  )
}
